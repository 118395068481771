// Import the server-side function to fetch product information
import { getInformationServer } from '~/services/server';
// Import createSignal for reactive state management in SolidJS
import { createSignal } from 'solid-js';
// Import useParams to extract URL parameters (such as the product id)
import { useParams } from '@solidjs/router';
import { Title, MetaProvider, Meta } from '@solidjs/meta';

// Define the shape of a Product object
export interface Product {
    title: string;
    description: string;
    imgUrl: string;
}

// The QrPage component renders product information based on a URL parameter
export const QrPage = () => {
    // Create reactive signals for product data and error messages
    const [product, setProduct] = createSignal<Product | null>(null);
    const [error, setError] = createSignal<string>('');
    // Extract route parameters from the URL (e.g., the product id)
    const params = useParams();

    // Define an async function to fetch product information from the server
    const fetchProduct = async () => {
        try {
            // Get the id parameter from the URL (non-null assertion is used because we expect it to exist)
            const id = params.id;
            // Call the server function to retrieve product information using the id
            const resp = await getInformationServer(id!);
            // If the response does not contain valid data, simply return
            if (!resp?.result) throw Error('No result for the shorthex');
            // Otherwise, update the product signal with the received data
            setProduct(resp.result.data);
        } catch (err) {
            console.log(err);
            // If an error occurs during fetching, update the error signal with the error message
            window.location.href = 'https://shop.wearshare.com/';
            setError(String(err));
        }
    };

    // Immediately invoke fetchProduct to begin retrieving the product info as soon as the component runs
    fetchProduct();

    // Render the page:
    // - The product title is displayed in a header.
    // - The image is rendered within a rotated and scaled container.
    // - The product description is shown in a paragraph.
    // - Any error message is rendered if present.

    return (
      <>
        <MetaProvider>
          <Title>
            {product()?.title ? `WRSR | ${product()?.title}` : 'WRSR'}
          </Title>
          {product()?.title && (
            <Meta property="og:title" content={product()?.title} />
          )}
          {product()?.description && (
            <Meta property="og:description" content={product()?.description} />
          )}
        </MetaProvider>
        <div class="container mx-auto py-20 !px-0 grid justify-items-center">
          {/* Display the product title */}
          <h2 class="text-3xl font-bold">{product()?.title}</h2>
          <div class="w-full h-[100px] md:h-[150px] md:my-4 flex items-center justify-center">
            <div class="-rotate-90 scale-[0.14]">
              {/* Render the product image */}
              <img
                class="max-w-none object-contain w-full h-full"
                src={product()?.imgUrl}
              />
            </div>
          </div>
          {/* Display the product description */}
          <p class="text-xl text-center w-3/4 md:w-1/2 font-semibold">
            {product()?.description}
          </p>
          {/* Display the product image a second time, vertically under the description. */}
          <div class="mt-4">
            <img class="max-w-full object-contain" src={product()?.imgUrl} />
          </div>
          {/* Conditionally display any error message */}
          {error() && <p>{error()}</p>}
        </div>
      </>
    );
};